import {Col, Row, Spin} from "antd";
import {Bar, BarChart, LineChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, Line} from "recharts";
import React, {useEffect, useState} from "react";
import {getPortalMetricsData} from "../../services/api";

const chartsWidth = 1200;

const KEYS = {
    INST: "INSTALLATIONS",
    RENEWALS: "RENEWALS",
    DMA_INST: "DMA_INST",
    DMA_RENEWALS: "DMA_RENEWALS",
    ACTIVE_USERS: "ACTIVE_USERS",
    PLAN_RATIO: "PLAN_RATIO",

}

const ChartActiveUsers = ({data, lines}) => {
    return <LineChart width={chartsWidth} height={400} data={data}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="monthLabel" />
        <YAxis />
        <Tooltip />
        <Legend />
        {
            (lines || []).map(line =>
                <Line strokeWidth={3} dot={false} type="monotone" dataKey={line.key} stroke={line.color || "#8884d8"} />
            )
        }
        {/*<Line strokeWidth={3} dot={false} type="monotone" dataKey="currentLiability" stroke="#82ca9d" />*/}
        {/*<Line strokeWidth={3} dot={false} type="monotone" dataKey="planRatio" stroke="#ff0000" />*/}
        {/*<Line strokeWidth={3} dot={false} type="monotone" dataKey="numWarranty" stroke="#000" />*/}
        {/*<Line strokeWidth={3} dot={false} type="monotone" dataKey="dmaInst" stroke="purple" />*/}
        {/*<Line strokeWidth={3} dot={false} type="monotone" dataKey="dmaRenew" stroke="orange" />*/}
        {/*<Line strokeWidth={3} dot={false} type="monotone" dataKey="retentionRate" stroke="blue" />*/}
    </LineChart>;
}

const ChartInstallations = ({data, lines}) => {
    return (
        <BarChart
            width={chartsWidth}
            height={400}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="monthLabel" />
            <YAxis />
            <Tooltip />
            <Legend
                formatter={(value) => {
                    if (value === 'numInstallations') return 'Installations';
                    if (value === 'numRenewals') return 'Renewals';
                    return value;
                }}
            />
            {
                (lines || []).map(line =>
                    <Bar dataKey={line.key} fill={line.color || "#4472C4"} />
                )
            }
            {/*<Bar dataKey="numInstallations" fill="#4472C4" />*/}
            {/*<Bar dataKey="numRenewals" fill="green" />*/}
        </BarChart>

    )
}

export const PortalMetricsPage = () => {
    const [iData, setIData] = useState();

    useEffect(() => {
        getPortalMetricsData().then(response => {
            setIData(response?.data?.data);
        });
    }, []);

    if (!iData) {
        return <Spin size="large"/>
    }

    const ILine = ({header, lines}) =>
        <div>
            <h2>{header}</h2>
            <ChartActiveUsers data={iData?.points || []} lines={lines}/>
        </div>;

    const IBar = ({header, lines}) =>
        <div>
            <h2>{header}</h2>
            <ChartInstallations data={iData?.points || []} lines={lines}/>
        </div>;


    return (<div>
        <Row>
            <IBar header={"Monthly Installations"} lines={[{key: "numInstallations"}]} />
            <IBar header={"Monthly Renewals"} lines={[{key: "numRenewals", color: "Teal"}]} />
            <ILine header={"Active Users (Count)"} lines={[{key: "numActiveUsers"}]} />
            <ILine header={"Liability (Monthly Units)"} lines={[{key: "currentLiability", color: "orange"}]}/>
            <ILine header={"Subscription Plan Ratio (1 Year vs 3 Year)"} lines={[{key: "planRatio", color: "red"}]}/>
            <ILine header={"Devices under Warranty"} lines={[{key: "numWarranty", color: "green"}]}/>
            <ILine header={"90-DMA (Installations)"}  lines={[{key: "dmaInst", color: "magenta"}]}/>
            <ILine header={"90-DMA (Renewals)"} lines={[{key: "dmaRenew", color: "maroon"}]}/>
            <ILine header={"Retention Rate"} lines={[{key: "retentionRate", color: "purple"}]}/>
            <IBar header={"Sales"} lines={[{key: "salesTotal", color: "green"}]}/>
        </Row>
    </div>)
};