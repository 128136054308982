import axios from 'axios';
import Cookies from "js-cookie";
import {message} from "antd";

import * as _ from 'lodash';
import {kbConstants} from "../models/constants/kbConstants";
import {setMpCommonArgs, setMpUserAttributes, setMpUserIdentity} from "./analytics/mixpanel";

export const externalApiInstance = axios.create({
    timeout: 7000*2
})

const hostname = window.location.hostname;
const origin = window.location.origin;

export let baseUrl;

if (hostname.includes("localhost")) { // localhost without nginx, use api-server from local
    baseUrl = "http://localhost:4001/intelli";
} else if (hostname.includes("apihost")) { // localhost without nginx, use api-server from staging
    baseUrl = "https://staging.khatabuddy.com/api"
} else { // prod or (local using nginx)
    baseUrl = `${origin}/api`;
    // baseUrl = "https://app.khatabuddy.com/api";
}

export const exportedUrls = {
    baseUrl,
    origin,
}

axios.interceptors.request.use( (config) => {
    // const token = store.getState().session.token;
    // config.headers.Authorization =  token;
    if (!config.auth?.username) { // default auth not set in axios, will try to fetch from cookies
        const currentPass = Cookies.get('invPass');
        const currentUser = Cookies.get('invUser');
        config.auth = {
            username: currentUser || "",
            password: currentPass || "",
        }
    }
    return config;
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    console.log("## interceptor running ##");
    if (_.get(error,'response.status') === 401) {
        console.log("## intercepted 401 ##");
        Cookies.set("invUser", "");
        Cookies.set("invPass", "");
        message.error("Authentication Failed: Incorrect credentials");
        if (!window.location.pathname.includes("login")) {
            window.location = "/login";
        }
        return;
    }
    return error;
});

const axiosV2 = axios.create();
axiosV2.interceptors.response.use(response => response.data, err => err);

export default axios;

export const requestOtpV2 = async (data) =>
    axiosV2.post(`${baseUrl}/auth/internal/requestOtp`, data);

export const verifyLoginV2 = async (data) =>
    axiosV2.post(`${baseUrl}/auth/internal/verifyLogin`, data);

export const loginRequest = async (username, password, dispatch, actualLoginTry = "") => {
    try {
        const response = await axios.get(`${baseUrl}/appData?actualLoginTry=${actualLoginTry}`, (username ? {
            auth: {
                username, password
            }
        } : undefined));

        if (response.status === 200 && username) {
            axios.defaults.auth = {username, password};
            Cookies.set("invUser", username, {expires: kbConstants.LOGIN_EXPIRY});
            Cookies.set("invPass", password);
        }
        const pendingOrdersCount = _.get(response, 'data.data.miscData.ecommercePendingOrdersCount', 0)
        const miscData = _.get(response, "data.data.miscData", {});
        const user = response.data.data.user;
        if (user) { // logged in
            const {company, items} = user;
            if (!company.name) {
                user.showNewAccountNudge = true;
            }
            delete user.company;
            delete user.items;
            setMpUserIdentity(user._id);
            setMpUserAttributes({
                name: user.name,
                email: user.email,
                phone: user.phone,
            });
            setMpCommonArgs({
                companyName: company?.name || "--",
                companyId: company?._id || "--",
            });
            dispatch({user, company, items, pendingOrdersCount, miscData});
        }
        return user;
    } catch (err) {
        console.log('Authentication Failed - Invalid credentials');
    }
}

export const signup = async (data) => {
    return axios.post(`${baseUrl}/internal/signup`, data);
}

export const googleLogin = async (data) => {
    return axios.post(`${baseUrl}/internal/googleLogin`, data);
}

export const forgotPassword = async (data) => {
    return axios.post(`${baseUrl}/internal/forgotPassword`, data);
}

export const saveUser = async (data) => {
    const newPassword = data?.user?.password;
    const resp = await axios.post(`${baseUrl}/user`, data);
    if (newPassword) {
        axios.defaults.auth.password = newPassword;
        Cookies.set("invPass", newPassword);
    }
    return resp;
}

export const internalSaveUser = async (data) => {
    const newPassword = data?.user?.password;
    const resp = await axios.post(`${baseUrl}/internal/saveUser`, data);
    if (newPassword) {
        axios.defaults.auth = {
            username: data?.user?.email,
            password: data?.user?.password
        };
        Cookies.set("invUser", data?.user?.email, {expires: kbConstants.LOGIN_EXPIRY});
        Cookies.set("invPass", newPassword);
    }
    return resp;
}

export const getSubusers = async () => {
    return axios.get(`${baseUrl}/subusers`);
}

export const getVoucherPdf = async (voucherId) => {
    return fetch(`${baseUrl}/internal/pdf/${voucherId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/pdf',
        },
    });
}

export const exportReports = async (data) => {
    return fetch(`${baseUrl}/internal/export?` + new URLSearchParams(data), {
        method: 'GET',
        headers: {
            // 'Content-Type': 'application/zip',
        },
    });
}

export const generateReportsFile = (data) =>
    axios.get(`${baseUrl}/internal/export?` + new URLSearchParams(data), { responseType: 'blob' });

export const emailInvoice = async (data) => {
    return axios.post(`${baseUrl}/emailInvoice`, data);
}

export const submitHelpTicket = async (data) => {
    return axios.post(`${baseUrl}/helpTicket`, data);
}

export const deleteSubuser = async (userIndex) => {
    return axios.delete(`${baseUrl}/subuser/${userIndex}`);
}

export const addSubuser = async (data) => {
    return axios.post(`${baseUrl}/subuser`, data);
}

export const getLinkInfo = async (key) => {
    return axios.get(`${baseUrl}/internal/linkInfo/${key}`);
}

export const getTransactions = () => {
    return axios.get(`${baseUrl}/pg/transactions`);
}

export const getPayouts = () => {
    return axios.get(`${baseUrl}/pg/payouts`);
}

export const getLedgers = (type = "debtor") => {
    return axios.get(`${baseUrl}/ledgers?type=${type}`);
}

export const getTimeline = () => {
    return axios.get(`${baseUrl}/inventory/statement`);
}

export const saveLedger = (ledger) => {
    return axios.post(`${baseUrl}/ledger`, {ledger});
}

export const deleteLedger = (id) => {
    return axios.delete(`${baseUrl}/ledger/${id}`);
}

export const getVouchers = (type, id) => {
    return axios.get(`${baseUrl}/vouchers?type=${type}&id=${id || ""}`);
}

export const getInternalVouchers = (id, isRenewal) => {
    return axios.get(`${baseUrl}/internal/vouchers?id=${id || ""}&isRenewal=${isRenewal ? "yes" : "no"}`);
}

export const getInternalLedgerStatement = (ledgerId, from, to) => {
    return axios.get(`${baseUrl}/internal/statement?ledgerId=${ledgerId}&from=${from}&to=${to}`);
}

export const getSummary = () => {
    return axios.get(`${baseUrl}/summary`);
}

export const getDues = () => {
    return axios.get(`${baseUrl}/dues`);
}

export const getInternalCompany = (id) => {
    return axios.get(`${baseUrl}/internal/company/${id}`);
}

export const saveVoucher = (data) => {
    return axios.post(`${baseUrl}/voucher`, data);
}

export const saveCompany = (obj) => {
    return axios.post(`${baseUrl}/company`, {company: obj});
}

export const deleteVoucher = (id) => {
    return axios.delete(`${baseUrl}/voucher/${id}`);
}


export const deleteItem = (id) => {
    return axios.delete(`${baseUrl}/item/${id}`);
}

export const saveItem = (item) => {
    return axios.post(`${baseUrl}/item`, {item});
}

export const getBanners = () => {
    return axios.get(`${baseUrl}/banners`);
}

export const saveBanner = (bannerData) => {
    return axios.post(`${baseUrl}/banners`, {bannerData});
}

export const getActivations = () => {
    return axios.get(`${baseUrl}/activations`);
}

export const saveActivation = (activationData) => {
    return axios.post(`${baseUrl}/activations`, {activationData});
}

export const getCustomers = () =>
    axios.get(`${baseUrl}/subscription/customers`);

export const getInternalCustomer = (customerId) =>
    axios.get(`${baseUrl}/internal/subscription/customers?cid=${customerId}`);

// TI selfcare::

export const getProductData = (productId) => axios.get(`${baseUrl}/portal/internal/core/getProductData?productId=${productId}`);

export const getInternalPageData = (customerId) => axios.get(`${baseUrl}/portal/internal/selfcare/pageData/${customerId}`);

export const modifyUserCartInternal = (data) => axios.post(`${baseUrl}/portal/internal/selfcare/modifyCart`, data);

export const getSelfcareKycCurrentStatus = (deviceId) => axios.get(`${baseUrl}/portal/internal/selfcare/kyc/getCurrentStatus/${deviceId}`);

export const sendSelfcareKycPhoneOtp = (data) =>
    axios.post(`${baseUrl}/portal/internal/selfcare/kyc/sendOtp/contact`, data);

export const submitSelfcareKycPhoneOtp = (data) =>
    axios.post(`${baseUrl}/portal/internal/selfcare/kyc/submitOtp/contact`, data);

export const sendSelfcareKycAadhaarOtp = (data) =>
    axios.post(`${baseUrl}/portal/internal/selfcare/kyc/sendOtp/id`, data);

export const submitSelfcareKycAadhaarOtp = (data) =>
    axios.post(`${baseUrl}/portal/internal/selfcare/kyc/submitOtp/id`, data);

export const submitManualKycDetails = (data) =>
    axios.post(`${baseUrl}/portal/internal/selfcare/kyc/submitManualKycDetails`, data);

export const getKycForReview = () =>
    axios.get(`${baseUrl}/portal/internal/selfcare/kyc/getKycForReview`);

export const actionOnKycReview = (data) =>
    axios.post(`${baseUrl}/portal/internal/selfcare/kyc/actionOnKycReview`, data);

export const getPortalMetricsData = () =>
    axios.get(`${baseUrl}/v2.0/portal/internal/temp/reporting/graph`);

export const saveCustomer = (data) =>
    axios.post(`${baseUrl}/subscription/customer`, data);

export const getProducts = () =>
    axios.get(`${baseUrl}/subscription/products`);

// export const getInternalProducts = (cid) =>
//     axios.get(`${baseUrl}/internal/subscription/products?cid=${cid}`);

export const saveProduct = (data) =>
    axios.post(`${baseUrl}/subscription/product`, data);

export const saveSubscription = (data) =>
    axios.post(`${baseUrl}/subscription/subscription`, data);

export const getSubscriptions = () =>
    axios.get(`${baseUrl}/subscription/subscriptions`);

export const getRenewals = () =>
    axios.get(`${baseUrl}/subscription/renewals`);

export const saveRenewal = (data) =>
    axios.post(`${baseUrl}/subscription/renewal`, data);

export const getPayOrder = (orderId, completePayOrder = "n") =>
    axios.get(`${baseUrl}/internal/payOrder?orderId=${orderId}&completePayOrder=${completePayOrder}`);


export const savePayOrder = (data) =>
    axios.post(`${baseUrl}/internal/payOrder`, data);

export const getCoupons = () =>
    axios.get(`${baseUrl}/coupons`);

export const applyCoupon = (data) =>
    axios.post(`${baseUrl}/applyCoupon`, data);

export const getPlans = () =>
    axios.get(`${baseUrl}/getPlans`);

export const completePurchase = (data) =>
    axios.post(`${baseUrl}/completePurchase`, data);

export const getPucSales = (vehicleId="nil") =>
    axios.get(`${baseUrl}/puc/sales/${vehicleId}`);

export const savePucSale = (data) =>
    axios.post(`${baseUrl}/puc/sale`, data);

export const updatePucRenewal = (data) =>
    axios.post(`${baseUrl}/puc/updateRenewal`, data);

export const deletePucSale = (saleId) =>
    axios.delete(`${baseUrl}/puc/sale/${saleId}`);

export const deletePucVehicle = (vehicleId) =>
    axios.delete(`${baseUrl}/puc/deleteVehicle/${vehicleId}`);

export const getPucVehicles = () =>
    axios.get(`${baseUrl}/puc/expiring`);

export const notifyPucUser = (vehicleId) =>
    axios.post(`${baseUrl}/puc/notify/${vehicleId}`);

export const sendSMS = (data,type) =>
    axios.post(`${baseUrl}/private/sendSMS?type=${type}`, data);

export const getVouchersByLedgerId = (ledgerId, type) =>
    axios.get(`${baseUrl}/vouchersByLedgerId/${ledgerId}?type=${type}`);

export const fetchBankDetails = () => axios.get(`${baseUrl}/getbankDetails`);

export const changeDefaultBank = (body) => axios.put(`${baseUrl}/bankDetail/setDefault`,body);

//To Fetch Bank account details via ifsc code
export const fetchBankAccountWithIFSCCode = (ifscCode) =>
    externalApiInstance.get(`https://ifsc.razorpay.com/${ifscCode}`);

export const stockInOut = (data) => {
        return axios.post(`${baseUrl}/inventory/addRecord`, {record : data});
}

export const redirectToPay = async (data) => {
    const resp = await axios.post(`${baseUrl}/pg/internal/initiatePayment`, data);
    if (resp.data.success) {
        return resp.data.data.payOrder.orderId;
    } else (
    message.error(resp.data.message)
    )
}

export const initiateWalletRecharge = async (data) => {
    const resp = await axios.post(`${baseUrl}/wallet/initiateRecharge`, data);
    if (resp.data.success) {
        return resp.data.data.payOrder.orderId;
    } else (
    message.error(resp.data.message)
    )
}

export const fetchWalletTransactions = () => axios.get(`${baseUrl}/wallet/transactions`);

export const requestDemo = (body) => axios.post(`${baseUrl}/requestDemo`, body);

export const gstinValidation = async (gstin) => axios.post(`${baseUrl}/misc/validateGst/`, {"gstin":gstin});

